import React from 'react'

const Header = () => {
  return (
    <div className='ui fixed menu'>
        <div className='ui container center'>
            <h2>Flashaid NRI Plans</h2>
        </div>
    </div>
  )
}

export default Header
